import React, { useEffect } from 'react';
import { PrivacyPolicyId } from '../../enums';
import { MenuItems } from '../../interfaces';
import { MenuContent } from '../../components/MenuContent/MenuContent';
import styles from './PrivacyPolicy.module.scss';

export const PrivacyPolicy = () => {
  const menuItems: MenuItems[] = [
    {
      label: 'Definitions',
      id: PrivacyPolicyId.DEFINITIONS,
    },
    {
      label: 'Introduction',
      id: PrivacyPolicyId.INTRODUCTION,
    },
    {
      label: 'Owner and Data Controller',
      id: PrivacyPolicyId.OWNER_AND_DATA_CONTROLLER,
    },
    {
      label: 'Data Collection and Use',
      id: PrivacyPolicyId.DATA_COLLECTION_AND_USE,
    },
    {
      label: 'Data Processing Roles',
      id: PrivacyPolicyId.DATA_PROCESSING_ROLES,
    },
    {
      label: 'User Rights',
      id: PrivacyPolicyId.USER_RIGHTS,
    },
    {
      label: 'Additional information about Data collection and processing',
      id: PrivacyPolicyId.ADDITIONAL_INFORMATION_DATA_COLLECTION,
    },
    {
      label: 'Security Measures',
      id: PrivacyPolicyId.SECURITY_MEASURES,
    },
    {
      label: 'Documents Module',
      id: PrivacyPolicyId.DOCUMENTS_MODULE,
    },
    {
      label: 'Payment Module',
      id: PrivacyPolicyId.PAYMENT_MODULE,
    },
    {
      label: 'Notification Feature',
      id: PrivacyPolicyId.NOTIFICATION_FEATURE,
    },
    {
      label: 'Changes to This Privacy Policy',
      id: PrivacyPolicyId.CHANGE_PRIVACY_POLICY,
    },
    {
      label: 'Contact ',
      id: PrivacyPolicyId.CONTACT_US,
    },
  ];

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className={`${styles.privacyPolicy} container mt-5 p-5`}>
      <div className="row">
        <div className="col-9">
          <header>
            <h2>Privacy Policy</h2>
          </header>
          <p className="small">Last update: 11/02/2025</p>
          <h6 className="mt-3">Privacy Policy for ZamGov</h6>
          <p>
            To receive information about your Personal Data, the purposes, and
            the parties the Data is shared with, contact the Owner.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.DEFINITIONS}>
            1. Definitions
          </h4>
          <ul>
            <li>
              <p>
                <strong>User:</strong> The individual using this Application
                who, unless otherwise specified, coincides with the Data
                Subject.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Subject:</strong> The natural person to whom the
                Personal Data refers.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Processor (or Data Supervisor):</strong> The
                natural or legal person, public authority, agency, or other body
                that processes Personal Data on behalf of the Controller, as
                described in this privacy policy.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Controller (or Owner):</strong> The natural or
                legal person, public authority, agency, or other body which,
                alone or jointly with others, determines the purposes and means
                of the processing of Personal Data, including the security
                measures concerning the operation and use of this Application.
                The Data Controller, unless otherwise specified, is the Owner of
                this Application.
              </p>
            </li>
            <li>
              <p>
                <strong>This Application:</strong> How the Personal Data of the
                User is collected and processed.
              </p>
            </li>
            <li>
              <p>
                <strong>Service:</strong> The service provided by this
                Application as described in the relative terms (if available)
                and on this site/application.
              </p>
            </li>
          </ul>

          <h4 className="mt-4" id={PrivacyPolicyId.INTRODUCTION}>
            2. Introduction
          </h4>
          <p>
            Welcome to ZamGov, a mobile app suite for e-government services that
            provides a secure, centralized application for citizens, immigrants,
            and businesses. It features modules for identity verification,
            digital signatures, document management, notifications, and
            payments, ensuring seamless and secure interactions with government
            entities.
            <br />
            This privacy policy explains how we collect, use, and protect your
            personal data when you use our mobile application.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.OWNER_AND_DATA_CONTROLLER}>
            3. Owner and Data Controller
          </h4>
          <p>
            At ZamGov, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            collected and recorded by ZamGov and how we use it. If you have
            additional questions or require more information about our Privacy
            Policy, do not hesitate to contact us.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.DATA_COLLECTION_AND_USE}>
            4. Data Collection and Use
          </h4>
          <p>
            We collect personal data to provide and improve our services, ensure
            the security of your information, and comply with legal obligations.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.DATA_PROCESSING_ROLES}>
            5. Data Processing Roles
          </h4>
          <ul>
            <li>
              <p>
                <strong>Data Controller:</strong>The entity responsible for
                determining the purposes and means of processing your personal
                data. The Data Controller for ZamGov is the owner of this
                application.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Processor:</strong>The entity responsible for
                processing personal data on behalf of the Data Controller.
              </p>
            </li>
          </ul>

          <h4 className="mt-4" id={PrivacyPolicyId.USER_RIGHTS}>
            6. User Rights
          </h4>
          <p>
            As a user, you have the right to access, correct, delete, and
            restrict the processing of your personal data. You also have the
            right to data portability and the right to withdraw consent at any
            time, where consent is the legal basis for processing.
            <br />
            In particular, Users have the right to do the following:
          </p>
          <ul>
            <li>
              <p>
                <strong>Withdraw their consent at any time.</strong> Users have
                the right to withdraw consent where they have previously given
                their consent to the processing of their Personal Data.
              </p>
            </li>
            <li>
              <p>
                <strong>Object to processing of their Data.</strong> Users have
                the right to object to the processing of their Data if the
                processing is carried out on a legal basis other than consent.
                Further details are provided in the dedicated section below.
              </p>
            </li>
            <li>
              <p>
                <strong>Access their Data.</strong> Users have the right to
                learn if Data is being processed by the Owner, obtain disclosure
                regarding certain aspects of the processing and obtain a copy of
                the Data undergoing processing.
              </p>
            </li>
            <li>
              <p>
                <strong>Verify and seek rectification.</strong> Users have the
                right to verify the accuracy of their Data and ask for it to be
                updated or corrected.
              </p>
            </li>
            <li>
              <p>
                <strong>Restrict the processing of their Data.</strong> Users
                have the right, under certain circumstances, to restrict the
                processing of their Data. In this case, the Owner will not
                process their Data for any purpose other than storing it.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Have their Personal Data deleted or otherwise removed.
                </strong>{' '}
                Users have the right, under certain circumstances, to obtain the
                erasure of their Data from the Owner.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Receive their Data and have it transferred to another
                  controller.
                </strong>{' '}
                Users have the right to receive their Data in a structured,
                commonly used, and machine-readable format and, if technically
                feasible, to have it transmitted to another controller without
                any hindrance. This provision is applicable provided that the
                Data is processed by automated means and that the processing is
                based on the User&apos;s consent, on a contract which the User
                is part of or on pre-contractual obligations thereof.
              </p>
            </li>
            <li>
              <p>
                <strong>Lodge a complaint.</strong> Users have the right to
                bring a claim before their competent data protection authority.
              </p>
            </li>
          </ul>
          <h6 className="mt-3">
            Details about the right to object to processing
          </h6>
          <p>
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
            <br />
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To
            learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this
            document.
          </p>
          <h6 className="mt-3">How to exercise these rights</h6>
          <p>
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
          </p>

          <h4
            className="mt-4"
            id={PrivacyPolicyId.ADDITIONAL_INFORMATION_DATA_COLLECTION}
          >
            7. Additional information about Data collection and processing
          </h4>
          <h6 className="mt-3">Legal action</h6>
          <p>
            The User&apos;s Personal Data may be used for legal purposes by the
            Owner in Court or in the stages leading to possible legal action
            arising from improper use of this Application or the related
            Services.
            <br />
            The User declares to be aware that the Owner may be required to
            reveal personal data upon request of public authorities.
          </p>
          <h6 className="mt-3">
            Additional information about User&apos;s Personal Data
          </h6>
          <p>
            In addition to the information contained in this privacy policy,
            this Application may provide the User with additional and contextual
            information concerning particular Services or the collection and
            processing of Personal Data upon request.
          </p>
          <h6 className="mt-3">Information not contained in this policy</h6>
          <p>
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time. Please see the
            contact information at the footer of this page.
          </p>
          <h6 className="mt-3">Changes to this privacy policy</h6>
          <p>
            The Owner reserves the right to make changes to this privacy policy
            at any time by giving notice to its Users on this page and possibly
            within this Application- as far as technically and legally feasible
            – sending a notice to Users via any contact information provided to
            the Owner. It is strongly recommended to check this page often,
            referring to the date of the last modification listed at the top.
            <br />
            Should the changes affect processing activities performed based on
            the User&apos;s consent, the Owner shall collect new consent from
            the User, where required.
          </p>
          <h4 className="mt-4" id={PrivacyPolicyId.SECURITY_MEASURES}>
            8. Security Measures
          </h4>
          <p>
            We implement robust security measures to ensure the safety of your
            personal data, including:
          </p>
          <ul>
            <li>
              <p>Encryption of data during transmission and storage.</p>
            </li>
            <li>
              <p>Regular security audits.</p>
            </li>
            <li>
              <p>Access controls to prevent unauthorized access.</p>
            </li>
          </ul>
          <p>
            These measures are designed to protect against unauthorized access,
            disclosure, alteration, and destruction of your data.
          </p>
          <h4 className="mt-4" id={PrivacyPolicyId.DOCUMENTS_MODULE}>
            9. Documents Module
          </h4>
          <p>
            The application includes a documents module allowing users to
            preview and sign documents securely. This module manages user
            consent, data access permissions, and the application&apos;s overall
            privacy settings.
          </p>
          <h4 className="mt-4" id={PrivacyPolicyId.PAYMENT_MODULE}>
            10. Payment Module
          </h4>
          <p>
            The application includes a payment module that allows users to
            preview invoices and receipts securely. This module adheres to the
            same stringent security measures as the rest of the application.
          </p>
          <h4 className="mt-4" id={PrivacyPolicyId.NOTIFICATION_FEATURE}>
            11. Notification Feature
          </h4>
          <p>
            ZamGov leverages the ZamNotify feature to support dialogue between
            users and government entities. This ensures timely and efficient
            communication regarding user inquiries and government notifications.
          </p>
          <h4 className="mt-4" id={PrivacyPolicyId.CHANGE_PRIVACY_POLICY}>
            12. Changes to This Privacy Policy
          </h4>
          <p>
            We may update this privacy policy from time to time. Any changes
            will be posted on this page, and where appropriate, notified to you
            by email. We encourage you to review this policy periodically to
            stay informed about how we are protecting your information.
            <br />
            <br />
            Thank you for using ZamGov.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.CONTACT_US}>
            Contact Us
          </h4>
          <p>
            If you have a privacy concern, complaint, or any questions, You can
            contact us:
          </p>
          <ul>
            <li>
              <p>
                By email:&nbsp;
                <a href="mailto:info@eservices.gov.zm" className="text-link">
                  info@eservices.gov.zm
                </a>
              </p>
            </li>
            <li>
              <p>
                By phone number:&nbsp;
                <a href="tel:+260211428600" className="text-link">
                  +260 211 428 600
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className={`${styles.menu} col-3 position-fixed`}>
          <MenuContent menuItems={menuItems} />
        </div>
      </div>
    </section>
  );
};
