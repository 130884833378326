import React, { useCallback, useState } from 'react';
import { TabProps } from '../../interfaces';

export const Tab: React.FC<TabProps> = ({ tabs, defaultActiveTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabChange = useCallback(
    (index: number) => (): void => {
      setActiveTab(index);
    },
    [],
  );

  return (
    <div className="w-full max-w-md mx-auto tab">
      <div className="flex ">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 border-0 py-2 flex-1 text-center nav-item default-item me-1 rounded-top ${
              activeTab === index
                ? 'active text-primary border-bottom border-3 border-primary'
                : 'bg-white'
            }`}
            onClick={handleTabChange(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-4 bg-gray-100 mt-2 rounded-md">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};
