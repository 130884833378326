export enum Favicon {
  DotGov = 'favicon-dot-gov',
  Zam = 'favicon-zam',
}

export enum HeadTitle {
  DotGov = 'DotGovSign',
  Zam = 'ZamSign',
}

export enum ButtonType {
  Button = 'button',
  Link = 'link',
}
