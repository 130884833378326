import { FC, memo } from 'react';

import { Footer, Header, LoginForm } from '../../components';
import { globalConfig } from '../../configuration/config';

import styles from './Unverified.module.scss';

export const Unverified: FC = memo(() => {
  const handleSubmit = () => {
    window.location.replace(
      `${globalConfig.config.REACT_APP_API_URL}/login?redirectUri=${window.location.origin}`,
    );
  };

  return (
    <>
      <Header />
      <div className={`${styles.unverifiedWrapper} container-fluid min-vh-100`}>
        <div className="row justify-content-center align-content-center min-vh-95 flex-column">
          <LoginForm
            handleSubmit={handleSubmit}
            buttonLabel={'Sign in with ZamPass'}
            verified={false}
          />
        </div>
      </div>
      <Footer />
    </>
  );
});
