import { BaseSyntheticEvent } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { DocumentStatus } from '../enums';
import { DateRangeValueType, Details, FormField, Props } from '../interfaces';
import { FileType } from '../enums/file-type';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_KEY,
  PAGINATION_LIMIT,
} from '../constants';
import { VerifyDocumentResponse } from '../interfaces/api-response';
import { Claims } from '../interfaces/user-info';

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getDetails(
  data: VerifyDocumentResponse,
  fileName: string,
): Details {
  return {
    fileName,
    status: data.isValid ? DocumentStatus.SIGNED : DocumentStatus.CANCELED,
    signatures: data.results.map((item) => {
      return {
        signatory: item.signer,
        timeStamp: item.timeStamp,
        id: item.id,
        issuer: item.issuer,
      };
    }),
  };
}

export function getBase64StringFromDataURL(dataURL: string) {
  return dataURL?.replace('data:', '')?.replace(/^.+,/, '');
}

export function getBase64Image(img: any, width: number, height: number) {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
  const dataURL = canvas.toDataURL('image/png');

  return dataURL?.replace('data:', '')?.replace(/^.+,/, '');
}

export const uploadFile = (
  e: BaseSyntheticEvent,
  successFn: (file: FileType, uploadedFile: File) => void,
) => {
  const reader = new FileReader();
  const uploadedFile = e.target.files[0];
  reader.readAsDataURL(uploadedFile);
  reader.onload = () => successFn(reader.result, uploadedFile);
  reader.onerror = (error) => {
    console.error('Error: ', error);
  };
};

export const getFullName = (claims?: Claims) => {
  let res = '';
  const { firstName, lastName } = claims || {};
  if (firstName) res = firstName;
  if (lastName && !firstName) res = lastName;
  if (lastName && firstName) res += ` ${lastName}`;
  return res;
};

export const handleError = (error: AxiosError | any) => {
  toast.error(
    error?.response?.data?.message ||
      (error?.response?.data as string) ||
      DEFAULT_ERROR_MESSAGE,
  );
};

export const validate = (field: FormField, values: Props) => (value: string) =>
  field?.rules?.find((rule) => rule.validator(value, values))?.message;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function';

export function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const dispatchInputChangeEvent = (
  ref: HTMLElement,
  value = '',
  type = 'HTMLInputElement' as unknown,
): void => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window?.[type as number]?.['prototype' as any],
    'value',
  )?.set;
  nativeInputValueSetter?.call(ref, value);
  ref?.dispatchEvent(new Event('change', { bubbles: true }));
};

export const toJSONLocal = (date: Date) => {
  const local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 19).replace('T', ' ');
};

export const getValue = (
  source: unknown,
  path: string,
  defaultValue?: unknown,
) => {
  const paths = path
    .replace(/\[(\w+)\]/g, '.$1')
    .replace(/\["(\w+)"\]/g, '.$1')
    .replace(/\['(\w+)'\]/g, '.$1')
    .split('.');
  let result = source as Props;
  for (const p of paths) {
    result = result?.[p];
  }

  return result === undefined ? defaultValue : result;
};

export const handleNoDate = (date: unknown): string | undefined =>
  typeof date === 'string'
    ? date?.length && !date.includes('0001-01-01T')
      ? date
      : undefined
    : (date as string);

export const formatDate = (date?: string): string =>
  (date &&
    handleNoDate(date) &&
    new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(date))) ||
  '';

export const formatDateAndTime = (date?: string): string =>
  (date &&
    handleNoDate(date) &&
    new Intl.DateTimeFormat('default', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(new Date(date))) ||
  '';

export const getSearchParams = (searchParams: Props): Props => {
  const result: Props = {};
  searchParams.forEach((value: Props, key: string) => {
    result[key] = value;
  });

  return result;
};

export const formateDateFilter = (
  date: Date | string,
  format = 'fr-CA',
  timeZone?: string,
): string =>
  new Intl.DateTimeFormat(format, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  })?.format(new Date(date));

export const transformFilterDate = (
  date: DateRangeValueType,
  format = 'fr-CA',
): string => {
  const start = date[0] ? `${formateDateFilter(date[0], format)} - ` : '';
  const end = date[1] ? formateDateFilter(date[1], format) : '';
  if (!start && !end) {
    return '';
  }
  return `${start}${end}`;
};

export const updateSearchParams = (
  searchParams: Props,
  updates: Props,
  reset = false,
): string => {
  const params = new URLSearchParams(searchParams);
  Object.keys(updates).forEach((key) =>
    updates[key] && String(updates[key]).length > 0
      ? params.set(key, '' + updates[key])
      : params.delete(key),
  );
  reset && params.has(PAGE_KEY) && params.set(PAGE_KEY, '1');
  return params.toString();
};

export const getDefaultPaginationParams = (params: object): Props =>
  Object.assign({ page: 1, count: DEFAULT_PAGE_SIZE }, params);

export const getPaginationItems = (total: number, page: number): number[] => {
  let start: number, end: number;

  if (page <= 2) {
    start = 2;
    end = total > 4 ? PAGINATION_LIMIT : total;
  } else if (page >= total - 1) {
    start = total > 4 ? page - 3 + (total - page) : 2;
    end = total;
  } else {
    start = page - 1;
    end = page + 2;
  }

  return total > 1
    ? Array(end - start)
        .fill('')
        .map((_, index) => start + index)
    : [];
};

export const stopPropagation = (e: BaseSyntheticEvent) => {
  e.stopPropagation();
};
