import zamOne from '../../assets/images/icons/zam-one.png';
import zamTwo from '../../assets/images/icons/zam-two.png';
import { CardSettings } from '../../interfaces';
import step1 from '../../assets/images/icons/zam-upload-document.svg';
import step4 from '../../assets/images/icons/zam-download.svg';
import { routes } from '../../models';
import { Link } from 'react-router-dom';

export const ITEMS_CARD: CardSettings[] = [
  {
    icon: zamOne,
    altIcon: 'one',
    title: 'Upload Your Document',
    description:
      'Click the [Upload File] button or drag and drop a PDF file from your computer into the upload area.',
    img: step1,
  },
  {
    icon: zamTwo,
    altIcon: 'two',
    title: 'Get Document Status',
    description:
      'The system will automatically check the signature’s authenticity. If the document is signed, you will see its status and signature details.',
    img: step4,
  },
];

export const TITLE = 'How to Verify a Digital Signature';
export const CONFIG_STYLES = 'col-12 col-md-6 col-lg-6';
export const DESCRIPTION_AUTHENTICATE = (
  <p>
    To continue, check the{' '}
    <Link
      className="text-warning text-decoration-none"
      to={routes.userGuidelines}
    >
      User Guidelines
    </Link>{' '}
    and authenticate via ZamPass.
  </p>
);
export const LABEL_BTN_SIGN = 'Sign In with ZamPass';
