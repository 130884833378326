import verifyAccount from '../../assets/images/icons/zam-verify-account.png';
import zamOne from '../../assets/images/icons/zam-one.png';
import zamTwo from '../../assets/images/icons/zam-two.png';
import zamThree from '../../assets/images/icons/zam-three.png';
import { CardSettings, Tab } from '../../interfaces';
import { ButtonType } from '../../enums';
import appleStore from '../../assets/images/apple.svg';
import googleStore from '../../assets/images/google.svg';
import { QRCode } from 'react-qrcode-logo';
import mobileLogo from '../../assets/images/mobile_logo.svg';
import { globalConfig } from '../../configuration/config';
import { SignDocument } from '../ESignDocument/sign-document';
import { VerifySignature } from '../VerifyESignature/verify-signature';
import { Content } from './Content';

export const TITLE = 'Important: Digital Signing via ZamSign';
export const SUB_TITLE =
  'To ensure a secure and verified signing process, please note that digital signing with ZamSign requires:';
export const CONFIG_STYLES = 'col-12 col-md-6 col-lg-4';

export const ITEMS_CARD: CardSettings[] = [
  {
    icon: zamOne,
    altIcon: 'one',
    title: 'Create a ZamPass Account',
    description:
      'ZamPass is a government authentication portal that ensures secure access to public services. It allows you to manage your digital identity, perform transactions, and securely sign documents with ZamSign.',
    btnConfig: [
      {
        type: ButtonType.Button,
        label: 'Go to ZamPass',
      },
    ],
  },
  {
    icon: zamTwo,
    altIcon: 'two',
    title: 'Install the ZamGov App',
    description:
      'Download and install the ZamGov App on your smartphone. You will need an internet connection for setup. Once installed, log in with ZamPass account to explore features.',
    children: (
      <>
        <div className="d-flex justify-content-between gap-1 w-100 mt-3 flex-sm-row flex-column align-items-center">
          <div>
            <div className="mb-2">
              <a href={globalConfig.config.REACT_APP_IOS_STORE_URL}>
                <img className="mx-auto" src={appleStore} alt="Apple Store" />
              </a>
            </div>
            <div>
              <a href={globalConfig.config.REACT_APP_GOOGLE_STORE_URL}>
                <img className="mx-auto" src={googleStore} alt="Google Play" />
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-grow-1">
            or
          </div>
          <div>
            <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
              <QRCode
                size={95}
                quietZone={5}
                value={globalConfig.config.REACT_APP_DYNAMIC_STORE_URL}
                logoImage={mobileLogo}
                removeQrCodeBehindLogo={true}
                ecLevel="H"
                qrStyle="dots"
                logoWidth={30}
                id="dynamic-store-qr-code"
              />
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    icon: zamThree,
    altIcon: 'three',
    title: 'Verify Your ZamPass Account',
    description:
      'Complete the enrollment process using the ZamGov app to fully activate your account and enable digital signing.',
    img: verifyAccount,
  },
];

export const TABS: Tab[] = [
  { label: 'Digital Signing', content: <Content /> },
  { label: 'How to E-Sign', content: <SignDocument /> },
  { label: 'How to Verify E-Signature', content: <VerifySignature /> },
];
