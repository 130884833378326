import { useContext } from 'react';

import { AuthContext } from '../../store/AuthContext';
import { Gender } from '../../enums';
import { formatDate } from '../../utils/utils';

export const UserInfo = () => {
  const { firstName, lastName, dateOfBirth, nationalId, gender, middleName } =
    useContext(AuthContext)?.claims || {};

  return (
    <>
      <div className="row p-1 user-info">
        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-7 col-xl-6">
          <div className="card border-0 mb-3">
            <div className="card-body">
              {firstName && (
                <div className="mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    className="form-control text-inherit"
                    value={firstName || ''}
                    placeholder="First Name"
                    disabled
                  />
                </div>
              )}
              {middleName && (
                <div className="mb-3">
                  <label className="form-label">Middle Name</label>
                  <input
                    className="form-control text-inherit"
                    value={middleName || ''}
                    placeholder="Middle Name"
                    disabled
                  />
                </div>
              )}
              {lastName && (
                <div className="mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    className="form-control text-inherit"
                    value={lastName || ''}
                    placeholder="Last Name"
                    disabled
                  />
                </div>
              )}
              {dateOfBirth && (
                <div className="mb-3">
                  <label className="form-label">Birth Date</label>
                  <input
                    className="form-control text-inherit"
                    value={formatDate(dateOfBirth) || ''}
                    placeholder="Birth Date"
                    disabled
                  />
                </div>
              )}
              {gender && (
                <div className="mb-3">
                  <label className="form-label">Gender</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={gender}
                    disabled
                  >
                    <option value={Gender.UNSPECIFIED}>Unspecified</option>
                    <option value={Gender.MALE}>Male</option>
                    <option value={Gender.FEMALE}>Female</option>
                  </select>
                </div>
              )}
              {nationalId && (
                <div className="mb-3">
                  <label className="form-label">National ID</label>
                  <input
                    className="form-control text-inherit"
                    value={nationalId || ''}
                    placeholder="National ID"
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
