import { FC, useContext } from 'react';
import { CardSettings } from '../../interfaces';

import styles from './InfoCard.module.scss';
import { AuthContext } from '../../store/AuthContext';

export const InfoCard: FC<CardSettings> = ({
  icon,
  title,
  img,
  altImg,
  btnConfig,
  description,
  altIcon,
  configStyle,
  children,
}: CardSettings) => {
  const { isAuthenticated, redirectToLogin } = useContext(AuthContext);

  return (
    <>
      <div className={configStyle}>
        <div className="card shadow border-0 text-center h-100 pt-5 pb-4 px-4 position-relative d-flex justify-content-center">
          <img
            src={icon}
            alt={altIcon}
            className={`${styles.icon} position-absolute`}
          />
          <div className="flex-grow-1">
            <div className="d-flex justify-content-center">
              {img && <img src={img} alt={altImg} />}
            </div>
            <h6 className="mt-4">{title}</h6>
            <p>{description}</p>
          </div>
          <div className="d-flex mt-2 justify-content-around">
            {btnConfig &&
              btnConfig.map((item, idx) => (
                <button key={idx} onClick={redirectToLogin} className="btn btn-primary px-5">
                  {item.label}
                </button>
              ))}
            {children}
          </div>
        </div>
        <div></div>
      </div>
    </>
  )
};
