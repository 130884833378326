import { Link } from 'react-router-dom';

import DotgovFooterLogo from '../../assets/images/dotgov-footer-logo.webp';
import ZamFooterLogo from '../../assets/images/zam-footer-logo.svg';
import styles from './Footer.module.scss';
import { routes } from '../../models';
import { DOTGOV_FOOTER_TEXT, ZAM_FOOTER_TEXT } from '../../constants';
import { Theme } from '../../enums';
import { globalConfig } from '../../configuration/config';

export const Footer = () => (
  <footer className="bottom-0 w-100 pt-3 position-relative bg-black">
    <div className={`${styles.footer} container pt-2 pb-1`}>
      <div className="row bg-black my-2 d-flex justify-content-evenly">
        <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4 d-flex flex-column align-items-center">
          <div className="logo">
            <img
              src={
                globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                  ? ZamFooterLogo
                  : DotgovFooterLogo
              }
              alt="DotGov Sign"
              className="img-fluid mb-3"
            />
          </div>
          <div className="w-75">
            <p className="p5-400 text-white text-center">
              {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM ? (
                <>
                  ZamSign - Government <br /> Digital Signature Service
                </>
              ) : (
                'DotGovSign - E-Government Digital Signature Service'
              )}
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4">
          <p className="mb-2 p2-300 text-white open-sans-light">Useful Links</p>
          <hr className="text-grey-4 mt-2" />
          <ul className="p-0">
            <Link
              to={routes.about}
              className="nav-link text-warning p-0 my-2 p3-600"
            >
              About
            </Link>
            <Link
              to={routes.deleteAccount}
              className="nav-link text-warning p-0 my-2 p3-600"
            >
              How to delete my ZamPass account
            </Link>
            <Link to={routes.faq} className="nav-link text-warning p-0 p3-600">
              FAQ
            </Link>
            <Link
              to={routes.termsAndConditions}
              className="nav-link text-warning p-0 my-2 p3-600"
            >
              Terms and Conditions
            </Link>
            <Link
              to={routes.privacyPolicy}
              className="nav-link text-warning my-2 p-0 p3-600"
            >
              ZamGov Privacy Policy
            </Link>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4">
          <p className="mb-2 p2-300 text-white open-sans-light">Contacts</p>
          <hr className="text-grey-4 mt-2" />
          <div>
            <p className="mb-1 p3-400 text-white">Phone:</p>
            <div>
              <a className="text-main custom-fs-1 text-white text-decoration-none">
                +260{' '}
              </a>
              <a
                href="tel:+260211 428 600"
                className="custom-fs-2 text-decoration-none text-warning"
              >
                211 428 600
              </a>
            </div>
            <small className="text-disabled p5-400">(short code: 2008)</small>
          </div>
          <div className="mt-2">
            <p className="m-0 p3-400 text-white">Email:</p>
            <a
              href="mailto: info@eservices.gov.zm"
              className="text-warning p3-400 text-decoration-none"
            >
              info@eservices.gov.zm
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-success py-1">
      <div
        className={`${styles.copyright} copyright container fst-normal d-flex justify-content-center`}
      >
        <span className="text-white custom-fs-5">
          {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
            ? ZAM_FOOTER_TEXT
            : DOTGOV_FOOTER_TEXT}
        </span>
      </div>
    </div>
  </footer>
);
