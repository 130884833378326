import { memo, useContext } from 'react';
import {
  CONFIG_STYLES,
  ITEMS_CARD,
  TITLE,
  DESCRIPTION_AUTHENTICATE,
  LABEL_BTN_SIGN,
} from './constants';
import { InfoCard } from '../../components';
import { AuthContext } from '../../store/AuthContext';

export const VerifySignature = memo(() => {
  const { isAuthenticated, redirectToLogin } = useContext(AuthContext);

  return (
    <section className="container mt-5 p-5">
      <h3 className="pb-4">{TITLE}</h3>
      <div className="row g-3 mt-4">
        {ITEMS_CARD.map((item, idx) => (
          <InfoCard
            key={idx}
            icon={item.icon}
            altIcon={item.altIcon}
            title={item.title}
            description={item.description}
            img={item.img}
            configStyle={CONFIG_STYLES}
          />
        ))}
      </div>
      {!isAuthenticated && (
        <div className="mt-4 fs-6">
          <p>{DESCRIPTION_AUTHENTICATE}</p>
          <button onClick={redirectToLogin} className="btn btn-primary">
            {LABEL_BTN_SIGN}
          </button>
        </div>
      )}
    </section>
  );
});
