import {
  useContext,
  BaseSyntheticEvent,
  FC,
  memo,
  useMemo,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import { AuthContext } from '../../store/AuthContext';
import { getFullName } from '../../utils/utils';
import { useUser } from '../../hooks';
import { routes } from '../../models';
import { DEFAULT_FILTERS } from '../../constants';

import DotgovHeaderLogo from '../../assets/images/dotgov-header-logo.svg';
import ZamHeaderLogo from '../../assets/images/zam-header-logo.svg';
import avatar from '../../assets/images/avatar.svg';
import logout from '../../assets/images/icons/zam-logout.svg';
import { globalConfig } from '../../configuration/config';
import { Theme } from '../../enums';

import styles from './Header.module.scss';

const ProfileLink: FC<{
  fullName: string;
  imageIndex: number;
}> = memo(({ fullName, imageIndex }) => {
  const { getAvatar } = useUser();
  return (
    <div className="d-flex align-items-center p-1 profile-link">
      <div className="d-flex">
        <img
          className={imageIndex ? 'rounded-circle' : ''}
          src={imageIndex !== 0 ? getAvatar(imageIndex) : avatar}
          width={40}
          height={40}
          alt="Avatar"
        />
      </div>
      <div className="d-flex align-items-center px-1">
        <p className="fw-semibold mb-0">{fullName}</p>
      </div>
      <div className="d-flex pe-2">
        <i className="icon-chevron-down text-primary" />
      </div>
    </div>
  );
});

export const Header: FC = memo(() => {
  const { isAuthenticated, claims, imageIndex } = useContext(AuthContext);
  const { pathname } = useLocation();

  const onLogoutHandler = (event: BaseSyntheticEvent) => {
    const config = isAuthenticated ? 'logout' : 'login';
    window.location.replace(
      `${globalConfig.config.REACT_APP_API_URL}/${config}?redirectUri=${window.location.origin}`,
    );
    event.preventDefault();
  };

  const verified = useMemo(() => Number(claims?.trustLevel) > 1, [claims]);
  const hasFullAccess = useMemo(
    () => verified && isAuthenticated,
    [verified, isAuthenticated],
  );

  const containerClass = useMemo(
    () =>
      [
        routes.profileArchive,
        routes.profileHistory,
        routes.profileInfo,
      ].includes(pathname)
        ? 'container-fluid'
        : 'container',
    [pathname],
  );

  const handleRouts = useCallback(
    (rout: string): string =>
      hasFullAccess || !isAuthenticated ? rout : routes.unverified,
    [verified, isAuthenticated],
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white custom-header fixed-top p-1">
      <div className={containerClass}>
        <Link
          to={routes.home}
          onClick={() => window.scrollTo(0, 0)}
          className="text-primary d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            src={
              globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                ? ZamHeaderLogo
                : DotgovHeaderLogo
            }
            alt="DotGov Sign"
            className="img-fluid"
          />
          <div className="ps-1 logo-text">
            {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
              ? 'ZamSign - Government Digital Signature Service'
              : 'DotGov Sign - Administration Sign Portal'}
          </div>
        </Link>

        <ul className="nav nav-pills d-flex align-items-center gap-1">
          {
            <>
              <li
                className={`nav-item default-item ${
                  pathname.includes(
                    verified ? routes.documentSign : routes.eSignDocument,
                  )
                    ? 'active'
                    : ''
                }`}
              >
                <h6 className="m-0">
                  <Link
                    to={
                      hasFullAccess
                        ? routes.documentSign
                        : handleRouts(routes.eSignDocument)
                    }
                    className="nav-link text-main semi-bold fst-normal"
                  >
                    E-Sign Document
                  </Link>
                </h6>
              </li>
              <li
                className={`nav-item default-item me-3 ${
                  pathname.includes(routes.documentVerify) ? 'active' : ''
                }`}
              >
                <h6 className="m-0">
                  <Link
                    to={routes.documentVerify}
                    className="nav-link text-main semi-bold fst-normal"
                  >
                    Verify E-Signature
                  </Link>
                </h6>
              </li>
              <li
                className={`nav-item default-item me-3 ${
                  pathname.includes(routes.userGuidelines) ? 'active' : ''
                }`}
              >
                <h6 className="m-0">
                  <Link
                    to={routes.userGuidelines}
                    className="nav-link text-main semi-bold fst-normal"
                  >
                    User Guidelines
                  </Link>
                </h6>
              </li>
            </>
          }
          {isAuthenticated ? (
            <li className="nav-item">
              <div
                className={`${styles.menu} justify-content-end align-items-center`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  {isAuthenticated ? (
                    <li>
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          className={`no-hover profile-link-dropdown p-0 ${styles.dropdownToggle}`}
                          variant="success"
                        >
                          <ProfileLink
                            imageIndex={imageIndex}
                            fullName={getFullName(claims)}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            hidden={!hasFullAccess}
                            href={routes.profileInfo}>
                            <div className="d-flex align-items-center p-1">
                              <div className={`d-flex ${styles.dropdownItem}`}>
                                <i
                                  style={{ fontSize: '24px' }}
                                  className="icon-user text-primary"
                                />
                              </div>
                              <div className="d-flex">Profile Information</div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            hidden={!hasFullAccess}
                            href={`${routes.profileHistory}?page=${DEFAULT_FILTERS.page}&count=${DEFAULT_FILTERS.count}`}
                          >
                            <div className="d-flex align-items-center p-1">
                              <div className={`d-flex ${styles.dropdownItem}`}>
                                <i
                                  style={{ fontSize: '20px' }}
                                  className="icon-clock text-primary"
                                />
                              </div>
                              <div className="d-flex">Signature History</div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            hidden={!hasFullAccess}
                            href={`${routes.profileArchive}?page=${DEFAULT_FILTERS.page}&count=${DEFAULT_FILTERS.count}`}
                          >
                            <div className="d-flex align-items-center p-1">
                              <div className={`d-flex ${styles.dropdownItem}`}>
                                <i
                                  style={{ fontSize: '20px' }}
                                  className="icon-archive text-primary"
                                />
                              </div>
                              <div className="d-flex">Documents Archive</div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href={`${globalConfig.config.REACT_APP_AUTH_URL}/settings/profile`}
                            target="new_blank"
                          >
                            <div className="d-flex align-items-center p-1">
                              <div className={`d-flex ${styles.dropdownItem}`}>
                                <i
                                  style={{ fontSize: '15px' }}
                                  className="icon-zampass-account text-primary"
                                />
                              </div>
                              <div className="d-flex">
                                Manage my ZamPass Account
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={onLogoutHandler}>
                            <div className="d-flex align-items-center p-1">
                              <div className={`d-flex ${styles.dropdownItem}`}>
                                <i
                                  style={{ fontSize: '23px' }}
                                  className="icon-logout text-primary"
                                />
                              </div>
                              <div className="d-flex">Logout</div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  ) : (
                    <li className="nav-item default-item">
                      <h6 className="m-0">
                        <Link
                          onClick={onLogoutHandler}
                          to=""
                          className="nav-link text-main semi-bold fst-normal d-flex align-items-center"
                        >
                          <i
                            style={{ fontSize: '23px' }}
                            className="icon-logout text-primary pe-1"
                          />
                          Sign Out
                        </Link>
                      </h6>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          ) : null}
          {!isAuthenticated && (
            <li className="nav-item default-item d-flex align-items-center">
              <h6 className={`${isAuthenticated ? styles.logout : ''} mb-0`}>
                <Link
                  to={''}
                  className="nav-link text-main semi-bold fst-normal d-flex"
                  onClick={onLogoutHandler}
                >
                  <img src={logout} alt="logout" />
                  Sign In
                </Link>
              </h6>
            </li>
          )}
          {/* <li className="nav-item d-flex align-items-center"> */}
          {/*   <SwitchThemeCheckbox /> */}
          {/* </li> */}
        </ul>
      </div>
    </nav>
  );
});
