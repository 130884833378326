import React, { useState } from 'react';

import { Claims, APIResponse } from '../interfaces/user-info';
import { useUser } from '../hooks';
import { globalConfig } from '../configuration/config';

const DEFAULT_SESSION = {
  isAuthenticated: false,
};

interface AuthContextProps {
  isAuthenticated: boolean;
  imageIndex: number;
  claims?: Claims;
  login: (session: APIResponse) => void;
  logout: () => void;
  redirectToLogin: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  isAuthenticated: false,
  imageIndex: 0,
  login: () => ({}),
  logout: () => ({}),
  redirectToLogin: () => ({}),
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [session, setSession] = useState<any>(DEFAULT_SESSION);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const { checkAvatar } = useUser();

  const loginHandler = (userSession: APIResponse) => {
    setSession(userSession);
    checkAvatar().then(({ data }) => {
      setImageIndex(data ? 1 : 0);
    });
  };

  const logoutHandler = () => {
    setSession({ isAuthenticated: false });
  };

  const redirectToLogin = () => {
    window.location.replace(
      `${globalConfig.config.REACT_APP_API_URL}/login?redirectUri=${window.location.origin}`,
    );
  };

  const updateImage = (index: number) => setImageIndex(index);

  const contextValue = {
    isAuthenticated: session.isAuthenticated,
    imageIndex,
    claims: session.claims,
    login: loginHandler,
    logout: logoutHandler,
    updateImage,
    redirectToLogin,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
