import { memo, useContext, useMemo } from 'react';
import { TABS } from './constant';
import { Tab } from '../../components';
import { AuthContext } from '../../store/AuthContext';
import { Content } from './Content';

export const UserGuidelines = memo(() => {
  const { isAuthenticated, claims } = useContext(AuthContext);
  const verified = useMemo(() => Number(claims?.trustLevel) > 1, [claims]);

  return (
    <section className="container mt-5 p-5">
      {isAuthenticated && (
        <Tab
          tabs={TABS}
          defaultActiveTab={!verified && isAuthenticated ? 0 : 1}
        />
      )}
      {!isAuthenticated && <Content />}
    </section>
  );
});
