import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../models';

export const About = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    navigate(routes.home);
  }, []);

  return (
    <section className="container mt-5 p-5">
      <div className="row d-flex justify-content-center">
        <div className="col-8">
          <header className="mb-4">
            <h2>About</h2>
          </header>
          <h4 className="mb-4">
            Welcome to ZamSign – Government Digital Signature Service
          </h4>
          <p className="mb-4">
            ZamSign is your trusted platform for secure, reliable, and
            easy-to-use digital signatures. It’s designed to help individuals,
            businesses, and public institutions in Zambia apply and verify
            electronic signatures seamlessly, ensuring enhanced security for all
            your digital interactions.
          </p>
          <p className="mb-4">
            We’re committed to providing a top-tier digital signature service,
            focusing on security, accessibility, and convenience.
          </p>
          <p className="mb-4">
            The platform’s interface is intuitive and user-friendly, making it
            easy for anyone to sign documents electronically — whether you’re a
            business professional, a civil servant, or an everyday citizen. No
            more printing, scanning, or waiting in queues — with ZamSign, you
            can sign documents anytime, anywhere.
          </p>
          <p className="mb-4">
            ZamSign supports a variety of secure electronic signature solutions,
            ensuring authenticity and protecting your data. Whether you’re
            handling government forms, business contracts, or personal
            documents, the system ensures your signature remains valid and
            legally binding.
          </p>
          <p className="mb-4">
            One of the biggest benefits of ZamSign is accessibility. Whether
            you’re in Zambia or abroad, you can securely apply and verify your
            electronic signature from anywhere. ZamSign leverages advanced
            encryption and the latest security measures to ensure your data
            remains confidential — only you and your service provider can access
            it.
          </p>
          <p className="mb-4">
            Established in 2025, ZamSign opens new opportunities for Zambian
            citizens, businesses, and public services to embrace secure digital
            interactions. We’re passionate about simplifying your journey to a
            fully digital experience, and we hope you find ZamSign as empowering
            and convenient as we envisioned.
          </p>
          <p className="mb-4">
            If you have any questions or feedback, feel free to contact us —
            we’re here to help.
          </p>

          <p className="mb-4">
            Sincerely,
            <br />
            The ZamSign Team
            <br />
            Smart Zambia Institute
          </p>
          <button className="btn btn-primary w-25" onClick={handleSubmit}>
            Back to Home
          </button>
        </div>
      </div>
    </section>
  );
};
