export const routes = {
  login: '/login',
  unverified: '/unverified',
  home: '/',
  documentSign: '/document/sign',
  documentVerify: '/document/verify',
  wildcard: '/*',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  profileInfo: '/profile/info',
  profileHistory: '/profile/history',
  profileArchive: '/profile/archive',
  faq: '/faq',
  deleteAccount: '/delete-account',
  userGuidelines: '/user-guidelines',
  eSignDocument: '/e-sign-document.tsx',
  verifyESignature: '/verify-e-signature',
  about: '/about',
};
